<template>
    <div class="margin_bottom_large">
        <h3>Pre-measure Form</h3>
        <div v-if="$appStore.isStaff()" class="margin_bottom cdi_button">
            <ProtectedLink :id="this.state.object.id" :linkType="'button'" :text="'Print'" classes="'cdi_button btn btn-default'" :queryString="''" :type="'preMeasure'" />
        </div>
        <div class="alert alert-info">
            Any units over 25 tons require to meet with contractor
        </div>
        <form>
            <div class="row">
                <div class="col-xs-12">
                    <h3>Contact</h3>
                    <div class="col-md-4 col-sm-6 col-xs-12 form-group">
                        <label>* Company</label>
                        <input type="text" class="form-control" v-model="formFields.contactCompany.val">
                    </div>
                    <div class="col-md-4 col-sm-6 col-xs-12 form-group">
                        <label>* Contact Name</label>
                        <input type="text" class="form-control" v-model="formFields.contactContactName.val">
                    </div>
                    <div class="col-md-4 col-sm-6 col-xs-12 form-group">
                        <label>* Phone</label>
                        <input type="text" class="form-control" v-model="formFields.contactPhone.val">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">
                    <h3>Measure Site</h3>
                    <div class="col-md-4 col-sm-6 col-xs-12 form-group">
                        <label>* Company</label>
                        <input type="text" class="form-control" v-model="formFields.measureSiteCompany.val">
                    </div>
                    <div class="col-md-4 col-sm-6 col-xs-12 form-group">
                        <label>* Contact Name</label>
                        <input type="text" class="form-control" v-model="formFields.measureSiteContactName.val">
                    </div>
                    <div class="col-md-4 col-sm-6 col-xs-12 form-group">
                        <label>* Phone</label>
                        <input type="text" class="form-control" v-model="formFields.measureSitePhone.val">
                    </div>
                    <div class="col-md-4 col-sm-6 col-xs-12 form-group">
                        <label>* Address</label>
                        <input type="text" class="form-control" v-model="formFields.measureSiteAddress.val">
                    </div>
                    <div class="col-md-4 col-sm-6 col-xs-12 form-group">
                        <label>* City</label>
                        <input type="text" class="form-control" v-model="formFields.measureSiteCity.val">
                    </div>
                    <div class="col-md-4 col-sm-6 col-xs-12 form-group">
                        <label>* State</label>
                        <select class="form-control" v-model="formFields.measureSiteState.val">
                            <option value="0">All</option>
                            <option v-for="[stateAbbr, state] in cache.stateCache.STATES" v-bind:key="stateAbbr" :value="stateAbbr">{{ state }}</option>
                        </select>
                    </div>
                    <div class="col-md-4 col-sm-6 col-xs-12 form-group">
                        <label>* Zip</label>
                        <input type="text" class="form-control" v-model="formFields.measureSiteZip.val">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 margin_bottom">
                    <h3>On-site</h3>
                    <div class="col-md-4 col-sm-6 col-xs-12 form_grid">
                        <label>
                            <input type="checkbox" v-model="formFields.requiredToMeetOnSite.val" :true-value="1" false-value="0">
                            Required To Meet On Site
                        </label>
                    </div>
                    <div class="col-md-4 col-sm-6 col-xs-12 form-group">
                        <label>Contact</label>
                        <input type="text" class="form-control" v-model="formFields.requiredToMeetOnSiteName.val">
                    </div>
                    <div class="col-md-4 col-sm-6 col-xs-12 form-group">
                        <label>Phone</label>
                        <input type="text" class="form-control" v-model="formFields.requireToMeetOnSitePhone.val">
                    </div>

                    <div class="col-md-4 col-sm-6 col-xs-12 form-group">
                        <label>Roof Access Location</label>
                        <input type="text" class="form-control" v-model="formFields.roofAccessLocation.val">
                    </div>
                    <div class="col-md-4 col-sm-6 col-xs-12 form-group">
                        <label>Roof Access Code</label>
                        <input type="text" class="form-control" v-model="formFields.roofAccessCode.val">
                    </div>
                    <div class="col-md-4 col-sm-6 col-xs-12 form-group">
                        <label>Roof Access Type</label><br>
                        <label><input type="radio" value="1" v-model="formFields.roofAccessTypeID.val"> Hatch</label>&nbsp;
                        <label><input type="radio" value="2" v-model="formFields.roofAccessTypeID.val"> Ladder</label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">
                    <div class="col-md-4 col-sm-6 col-xs-12 form-group">
                        <label>*Date Required</label>
                        <DatePicker v-model="formFields.dateRequired.val" :inputAttributes="{class: 'form-control'}" />
                    </div>
                    <div class="col-md-4 col-sm-6 col-xs-12 form_grid">
                        <label>
                            <input type="checkbox" v-model="formFields.areUnitsClearlyMarked.val" :true-value="1" :false-value="0">
                            Units Are Clearly Marked
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">
                    <div class="col-sm-12 form-group">
                        <label>Special Notes</label>
                        <textarea class="form-control vertical-resize" v-model="formFields.specialNotes.val"></textarea>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">
                    <strong>* {{ numCurbs }} curb(s) needing measurement</strong>
                    <div id="pre_measure_form_curbs"></div>
                </div>
            </div>

            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th>Line</th>
                        <th>RTU Tag</th>
                        <th>Old Model</th>
                        <th>Needs Measure</th>
                        <th>Placement</th>
                        <th>New Unit Accessories</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="quoteCurb in data.quote_curbs" :key="quoteCurb.quote_curb_id">
                        <td>{{ quoteCurb.line }}</td>
                        <td>{{ quoteCurb.tag }}</td>
                        <td>{{ quoteCurb.old_model }}</td>
                        <td>
                            <div class="col-xs-12">
                                <label><input type="checkbox" :true-value="1" :false-value="0" v-model="quoteCurb.needs_measure"> Measure</label>
                            </div>
                        </td>
                        <td>
                            <div class="col-xs-12">
                                <label><input type="checkbox" :true-value="1" :false-value="0" v-model="quoteCurb.previously_on_adapter"> Previously On Adapter</label>
                            </div>
                        </td>
                        <td>
                            <div class="col-md-4 col-sm-6 col-xs-12">
                                <label><input type="checkbox" :true-value="1" :false-value="0" v-model="quoteCurb.econo_selected"> Econo</label>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-12">
                                <label><input type="checkbox" :true-value="1" :false-value="0" v-model="quoteCurb.oa_hood_selected"> OA Hood</label>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-12">
                                <label><input type="checkbox" :true-value="1" :false-value="0" v-model="quoteCurb.power_exhaust_selected"> Power Exhaust</label>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div v-show="data.submitted_date">Date Submitted: {{ data.submitted_date }}</div>
            </div>                       

            <div class="pull-right btn-group margin_bottom">
                <input class="btn btn-default" type="button" value="Cancel" @click="close">
                <input v-show="data.quote_curbs.length" class="btn btn-default" type="button" value="Clear Measure Sheet" @click="confirmClear">
                <input class="btn btn-default" type="button" value="Save and Submit Later" @click="save(false)">
                <input class="btn btn-primary" type="button" value="Submit" @click="save(true)">
            </div>
        </form>
        <ConfirmBox
            v-if="confirmBox"
            :heading="'Clear Premeasure Sheet?'"
            :body="'Are you sure you want to clear this sheet and remove curbs from Measure Scheduler?'"
            @close="clear"
        />
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
import helpers from "@/store/helpers";
import stateCache from '@/cache/state.cache.js';
import DatePicker from '@/components/utils/DatePicker';
import ProtectedLink from "@/components/utils/ProtectedLink";
import ConfirmBox from '@/components/utils/ConfirmBox';

export default {
    name: "PreMeasure",
    components: {ProtectedLink, DatePicker, ConfirmBox },
    props: ['data'],
    data() {
        return {
            state: store.state,
            formFields: {
                contactCompany: { val: '', qsField: '[contact_company]' },
                dateRequired: { val: '', qsField: '[date_required]' },
                contactContactName: { val: '', qsField: '[contact_contact_name]' },
                contactPhone: { val: '', qsField: '[contact_phone]' },
                measureSiteCompany: { val: '', qsField: '[measure_site_company]' },
                measureSiteContactName: { val: '', qsField: '[measure_site_contact_name]' },
                measureSitePhone: { val: '', qsField: '[measure_site_phone]' },
                requiredToMeetOnSite: { val: '', qsField: '[required_to_meet_on_site]' },
                requiredToMeetOnSiteName: { val: '', qsField: '[required_to_meet_on_site_name]' },
                requireToMeetOnSitePhone: { val: '', qsField: '[required_to_meet_on_site_phone]' },
                roofAccessCode: { val: '', qsField: '[roof_access_code]' },
                roofAccessLocation: { val: '', qsField: '[roof_access_location]' },
                roofAccessTypeID: { val: '', qsField: '[roof_access_type_id]' },
                areUnitsClearlyMarked: { val: '', qsField: '[are_units_clearly_marked]' },
                specialNotes: { val: '', qsField: '[special_notes]' },
                measureSiteAddress: { val: '', qsField: '[measure_site_address]' },
                measureSiteCity: { val: '', qsField: '[measure_site_city]' },
                measureSiteState: { val: '', qsField: '[measure_site_state]' },
                measureSiteZip: { val: '', qsField: '[measure_site_zip]' },
            },
            cache: {
                stateCache,
            },
            confirmBox: false,
        }
    },
    methods: {
        save: function(submitting = false) {
            let params = helpers.getQSPairs(this.formFields);
            let quoteCurbID = 0;
            for (let i = 0; i < this.data.quote_curbs.length; i++) {
                quoteCurbID = this.data.quote_curbs[i].quote_curb_id;
                let accessories = [];
                if (this.data.quote_curbs[i].needs_measure == 1) {
                    params[`params[curbs_to_measure][${quoteCurbID}][needs_measure]`] = 1;
                }
                if (this.data.quote_curbs[i].previously_on_adapter == 1) {
                    params[`params[curbs_to_measure][${quoteCurbID}][previously_on_adapter]`] = 1;
                }

                if (this.data.quote_curbs[i].econo_selected == 1) {
                    accessories.push(1);
                }
                if (this.data.quote_curbs[i].oa_hood_selected == 1) {
                    accessories.push(2);
                }
                if (this.data.quote_curbs[i].power_exhaust_selected == 1) {
                    accessories.push(3);
                }
                if (accessories.length) {
                    params[`params[curbs_to_measure][${quoteCurbID}]`] = accessories;
                }
            }

            if (submitting) {
                params['params[submitting]'] = 1;
            }

            store.apiGeneric(`quote_pre_measure_sheet/save_pre_measure_sheet/${this.state.object.id}`, params)
                .then((results) => results != 'error' ? this.close() : '');
        },
        close: function() {
            this.state.currentView = 'details';
        },
        confirmClear: function (){
            this.confirmBox = true;
        },
        clear: function() {
            store.apiGeneric(`quote_pre_measure_sheet/clear_pre_measure_sheet/${this.state.object.id}`)
                .then((results) => results != 'error' ? this.close() : '');
            this.confirmBox = false;
        }
    },
    computed: {
        numCurbs: function() {
            let numCurbs = 0;
            if (this.data.quote_curbs) {
                for (let i = 0; i < this.data.quote_curbs.length; i++) {
                    if (this.data.quote_curbs[i].needs_measure == 1) {
                        numCurbs++;
                    }
                }
            }
            
            return numCurbs;
        },
    },
    watch: {
        data: function() {
            this.formFields.contactCompany.val = this.data.contact_company;
            this.formFields.dateRequired.val = this.data.date_required;
            this.formFields.contactContactName.val = this.data.contact_contact_name;
            this.formFields.contactPhone.val = this.data.contact_phone;
            this.formFields.measureSiteCompany.val = this.data.measure_site_company;
            this.formFields.measureSiteContactName.val = this.data.measure_site_contact_name;
            this.formFields.measureSitePhone.val = this.data.measure_site_phone;
            this.formFields.requiredToMeetOnSite.val = this.data.required_to_meet_on_site;
            this.formFields.requiredToMeetOnSiteName.val = this.data.required_to_meet_on_site_name;
            this.formFields.requireToMeetOnSitePhone.val = this.data.required_to_meet_on_site_phone;
            this.formFields.roofAccessCode.val = this.data.roof_access_code;
            this.formFields.roofAccessLocation.val = this.data.roof_access_location;
            this.formFields.roofAccessTypeID.val = this.data.roof_access_type_id;
            this.formFields.areUnitsClearlyMarked.val = this.data.are_units_clearly_marked;
            this.formFields.specialNotes.val = this.data.special_notes;
            this.formFields.measureSiteAddress.val = this.data.measure_site_address;
            this.formFields.measureSiteCity.val = this.data.measure_site_city;
            this.formFields.measureSiteState.val = this.data.measure_site_state;
            this.formFields.measureSiteZip.val = this.data.measure_site_zip;
        }
    }
}
</script>

<style scoped>
@media (max-width: 1699px) {
    .btn-group{
        display: block;
        margin-bottom: 60px;
    }
}

</style>