<template>
    <div>
        <form id="auto_drawing_form_adapter">
            <div class="row">
                <div class="col-md-3 form-group">
                    <div class="input-group">
                        <label class="conf_label input-group-addon">Config</label>
                        <select @change="updateErrorHandler" v-model="state.autoDrawing.config" class="form-control">
                            <option :value="errorHandlers.standard">A</option>
                            <option :value="errorHandlers.adapterB">B</option>
                            <option :value="errorHandlers.adapterC">C</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3 col-md-offset-3 form-group">
                    <div class="input-group">
                        <label class="od_label input-group-addon">OD1</label>
                        <input type="text" v-model.number="state.autoDrawing.od1" @keyup="updateLabel('od1', state.autoDrawing.od1)" class="form-control auto_drawing_od1">
                    </div>
                </div>
                <div class="col-md-3 form-group">
                    <div class="input-group">
                        <label class="od_label input-group-addon">OD2</label>
                        <input type="text" v-model.number="state.autoDrawing.od2" @keyup="updateLabel('od2', state.autoDrawing.od2)" class="form-control auto_drawing_od2">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 form-group">
                    <div class="input-group">
                        <label class="return_label input-group-addon">R1</label>
                        <input type="text" v-model.number="state.autoDrawing.r1" @keyup="updateLabel('r1', state.autoDrawing.r1)" class="form-control auto_drawing_r1">
                    </div>
                </div>
                <div class="col-md-3 form-group">
                    <div class="input-group">
                        <label class="return_label input-group-addon">R2</label>
                        <input type="text" v-model.number="state.autoDrawing.r2" @keyup="updateLabel('r2', state.autoDrawing.r2)" class="form-control auto_drawing_r2">
                    </div>
                </div>
                <div class="col-md-3 form-group">
                    <div class="input-group">
                        <label class="return_label input-group-addon">R3</label>
                        <input type="text" v-model.number="state.autoDrawing.r3" @keyup="updateLabel('r3', state.autoDrawing.r3)" class="form-control auto_drawing_r3">
                    </div>
                </div>
                <div class="col-md-3 form-group">
                    <div class="input-group">
                        <label class="return_label input-group-addon">R4</label>
                        <input type="text" v-model.number="state.autoDrawing.r4" @keyup="updateLabel('r4', state.autoDrawing.r4)" class="form-control auto_drawing_r4">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 form-group">
                    <div class="input-group">
                        <label class="supply_label input-group-addon">S1</label>
                        <input type="text" v-model.number="state.autoDrawing.s1" @keyup="updateLabel('s1', state.autoDrawing.s1)" class="form-control auto_drawing_s1">
                    </div>
                </div>
                <div class="col-md-3 form-group">
                    <div class="input-group">
                        <label class="supply_label input-group-addon">S2</label>
                        <input type="text" v-model.number="state.autoDrawing.s2" @keyup="updateLabel('s2', state.autoDrawing.s2)" class="form-control auto_drawing_s2">
                    </div>
                </div>
                <div class="col-md-3 form-group">
                    <div class="input-group">
                        <label class="supply_label input-group-addon">S3</label>
                        <input type="text" v-model.number="state.autoDrawing.s3" @keyup="updateLabel('s3', state.autoDrawing.s3)" class="form-control auto_drawing_s3">
                    </div>
                </div>
                <div class="col-md-3 form-group">
                    <div class="input-group">
                        <label class="supply_label input-group-addon">S4</label>
                        <input type="text" v-model.number="state.autoDrawing.s4" @keyup="updateLabel('s4', state.autoDrawing.s4)" class="form-control auto_drawing_s4">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 form-group">
                    <label style="height: 34px;">
                        <input type="checkbox" v-model="state.autoDrawing.isCenteredLength" @change="drawDiagram" :true-value="1" :false-value="0"> Centered Length
                    </label>
                </div>
                <div class="col-md-3 form-group">
                    <div v-show="!state.autoDrawing.isCenteredLength" class="input-group">
                        <label class="input-group-addon"><b>L</b> Offset (+/-)</label>
                        <input type="text" v-model="state.autoDrawing.lengthOffset" @keydown="drawDiagram" class="form-control">
                    </div>
                </div>
                <div class="col-md-3 form-group">
                    <label>
                        <input type="checkbox" v-model="state.autoDrawing.isCenteredWidth" @change="drawDiagram" :true-value="1" :false-value="0"> Centered Width
                    </label>
                </div>
                <div class="col-md-3 form-group">
                    <div v-show="!state.autoDrawing.isCenteredWidth" class="input-group">
                        <label class="input-group-addon"><b>W</b> Offset (+/-)</label>
                        <input type="text" v-model="state.autoDrawing.widthOffset" @keydown="drawDiagram" class="form-control">
                    </div>
                </div>
            </div>
            <div v-show="isEngineering">
                <div class="row">
                    <div class="col-md-3 form-group">
                        <div class="input-group">
                            <label class="custom_label input-group-addon">Curb Height OR</label>
                            <input type="text" v-model="state.autoDrawing.customOptions.curbHeightOverride" :true-value="1" :false-value="0" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-3 form-group">
                        <div class="input-group">
                            <label class="custom_label input-group-addon">Curb Oversize</label>
                            <input type="text" v-model="state.autoDrawing.customOptions.curbOversize" :true-value="1" :false-value="0" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-3 form-group">
                        <div class="input-group">
                            <label class="custom_label input-group-addon">Top Kick</label>
                            <input type="text" v-model="state.autoDrawing.customOptions.topKick" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-3 form-group">
                        <div class="input-group">
                            <label class="custom_label input-group-addon">3 x 3 Width</label>
                            <input type="text" v-model="state.autoDrawing.customOptions.threeXThreeWidth" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-3 form-group">
                        <div class="input-group">
                            <label class="custom_label input-group-addon">Hem Height OR</label>
                            <input type="text" v-model="state.autoDrawing.customOptions.hemHeightOverride" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-3 form-group">
                        <div class="input-group">
                            <label class="custom_label input-group-addon">Bottom Kick</label>
                            <input type="text" v-model="state.autoDrawing.customOptions.bottomKick" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 form-group">
                        <label>
                            <input type="checkbox" v-model="state.autoDrawing.customOptions.gaOverride12GA" :true-value="1" :false-value="0"> 12 GA Override
                        </label>
                    </div>
                    <div class="col-md-3 form-group">
                        <label>
                            <input type="checkbox" v-model="state.autoDrawing.customOptions.gaOverride14GA" :true-value="1" :false-value="0"> 14 GA Override
                        </label>
                    </div>
                    <div class="col-md-3 form-group">
                        <label>
                            <input type="checkbox" v-model="state.autoDrawing.customOptions.gaOverride16GA" :true-value="1" :false-value="0"> 16 GA Override
                        </label>
                    </div>
                    <div class="col-md-3 form-group">
                        <label>
                            <input type="checkbox" v-model="state.autoDrawing.customOptions.tunnelCurb" :true-value="1" :false-value="0"> Tunnel Curb
                        </label>
                    </div>
                </div>
            </div>
        </form>

        <canvas id="autoDrawingAdapter">Custom Drawing Diagram</canvas>
    </div>
</template>

<script>
    import { autoDrawingStore } from "@/components/businessObjects/quote/AutoDrawings/AutoDrawing.store";

    export default {
        name: "AutoDrawingAdapter",
        props: ["isEngineering"],
        data() {
            return {
                state: autoDrawingStore.state,
                errorHandlers: autoDrawingStore.errorHandlers
            }
        },
        methods: {
            updateLabel: function (key, value) {
                autoDrawingStore.updateLabel(key, value);
                autoDrawingStore.checkForErrors();
            },
            updateErrorHandler: function () {
                autoDrawingStore.setErrorHandler(this.state.autoDrawing.config);
                autoDrawingStore.drawDiagram();
            },
            drawDiagram: function () {
                autoDrawingStore.drawDiagram();
            }
        }
    }
</script>

<style scoped>

</style>