<template>
    <Modal :heading="'Search By Dimensions'" @close="close()" v-show="show">
        <template v-slot:body>
            <h3>Search by Dimensions</h3>
            <div class="row">
                <div class="col-sm-6 col-xs-12 form-group">
                    <label>Model Length (in)</label><input type="text" class="form-control" v-model="modelLength">
                </div>
                <div class="col-sm-6 col-xs-12 form-group">
                    <label>Model Width (in)</label><input type="text" class="form-control" v-model="modelWidth">
                </div>
                <div class="col-xs-12 form-group">
                    <input @click="searchDimension" type="button" value="Search Dimensions" class="btn btn-default">
                </div>
            </div>

            <!-- search results -->
            <div class="spacing_top">
                <div v-show="results.parts">
                    <table class="table table-responsive">
                        <tbody>
                        <tr v-for="(row, index) in results.parts" :key="index">
                            <td><div @click="selectPart(row)" class="">CDI {{ row.cdi_part_num }}</div></td>
                            <td>
                                <ProtectedLink
                                    :linkType="'iconText'"
                                    :classes="'glyphicon glyphicon-file cdi_icon'"
                                    :id="row.cdi_file_id"
                                    :queryString="''"
                                    :type="'drawing'"
                                    :text="row.title"
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    v-show="results.parts != false && !results.parts"
                    v-for="(row, index) in results"
                    :key="index"
                    class="col-sm-6 col-xs-12"
                >
                    <div @click="selectModel(row)" class="item_panel">{{ row.unit_model_num }}</div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import brandCache from '@/cache/brand.cache.js';
import { store } from '@/store/BusinessObject.store.js';
import ProtectedLink from "@/components/utils/ProtectedLink";
import Modal from "@/components/utils/Modal";
export default {
    name: "DimensionLookUp",
    components: {
        ProtectedLink,
        Modal
    },
    data() {
        return {
            brandID: 0,
            results: [],
            modelLength: '',
            modelWidth: '',
            unknownModel: '',
            show: false,
            cache: {
                brandCache,
            },
        }
    },
    computed: {
        baseUrl: function () {
            return process.env.VUE_APP_URL;
        }
    },
    methods: {
        searchBrand: function() {
            store.apiGeneric('quote/brand_list_json', { 'params[brand_id]': this.brandID, 'params[model_find_type]': 'old' })
                .then(results => this.results = results);
        },
        open: function() {
            this.show = true;
        },
        close: function() {
            this.brandID = 0;
            this.modelWidth = '';
            this.modelLength = '';
            this.unknownModel = '';
            this.results = [];
            this.show = false;
        },
        searchDimension: function() {
            store.apiGeneric('part/parts_by_dimension', { 'params[length]': this.modelLength, 'params[width]': this.modelWidth })
                .then(results => this.results = results);
        },
        selectModel: function(model) {
            this.$emit('selectModel', model);
            this.close();
        },
        selectPart: function(part) {
            this.$emit('selectPart', part);
            this.close();
        }
    },
}
</script>

<style scoped>

</style>