<template>
    <Modal :heading="'Drawing Request'" @close="close()" v-if="show">
        <template v-slot:body>
            <label>Drawing Type</label>
            <select v-model="drawingTypeID" class="form-control">
                <option value="3">Curb 3D</option>
                <option value="7">Flow</option>
            </select>
        </template>
        <template v-slot:footer>
            <button class="cdi_button btn btn-primary" type="button" @click="submit()">Submit</button>
            <button class="cdi_button btn btn-default" type="button" @click="close()">Cancel</button>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/utils/Modal';
import { store } from '@/store/BusinessObject.store.js';
export default {
    name: "DrawingRequest",
    components: {
        Modal
    },
    props: ['companyID'],
    data() {
        return {
            drawingTypeID: 3,
            trackerInvoiceID: 0,
            quoteCurbID: 0,
            curbID: 0,
            show: false,
        }
    },
    methods: {
        submit: function() {
            let params = {
                'params[drawing_type_id]': this.drawingTypeID,
                'params[quote_curb_id]': this.quoteCurbID,
                'params[tracker_invoice_id]': this.trackerInvoiceID,
                'params[curb_id]': this.curbID,
                'params[company_id]': this.companyID,
            }
            store.apiGeneric('drawsch/request_drawing', params)
                .then(() => this.clearForm());
            this.close();
            this.$emit('submitted')
        },
        open: function(quoteCurbID, trackerInvoiceID, curbID) {
            this.trackerInvoiceID = trackerInvoiceID;
            this.quoteCurbID = quoteCurbID;
            this.curbID = curbID;
            this.show = true;
        },
        close: function() {
            this.show = false;
            this.clearForm();
        },
        clearForm: function() {
            this.drawingTypeID = 3;
            this.quoteCurbID = 0;
            this.trackerInvoiceID = 0;
            this.curbID = 0;
        }
    }
}
</script>

<style scoped>

</style>