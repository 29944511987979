<template>
    <div class="col-sm-12">
        <h3>Notes</h3>
        <table class="table">
            <thead>
            <tr>
                <th :colspan="($appStore.isStaff() ? '2' : '1')">Note</th>
                <th style="width:20%">By</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="note in notes" :key="note.cdi_note_id">
                <td v-if="$appStore.isStaff()">
                    <span v-show="!note.checked" @click="checkNote(note.cdi_note_id)" class="glyphicon glyphicon-ok icon_md cdi_link spacing_right_small" title="Check this note"></span>
                    <span v-show="!note.strikeout" @click="$refs.confirm.open(note.cdi_note_id)" class="glyphicon glyphicon-ban-circle icon_md cdi_link" title="Strike text out"></span>
                </td>
                <td :class="{strikeout: note.strikeout}">
                    {{ note.internal ? '(Internal)' : '' }} {{ note.note_txt }}
                    <div v-for="(check, index) in note.checks" :key="index">&#10003; {{ check.checked_by }} ({{ check.check_dt }})</div>
                </td>
                <td :class="{strikeout: note.strikeout}">{{ note.note_user }}<br>{{ note.cdi_note_date }}</td>
            </tr>
            <tr v-if="$appStore.isStaff()">
                <td colspan="3"><label><input type="checkbox" :checked="checkNotes" @change="saveCheckNotes"> Show Notice on Scheduler</label></td>
            </tr>
            </tbody>
        </table>
        <Confirm ref="confirm" :heading="'Strikeout Note?'" :body="'Would you like to strikeout this Note?'" @response="strikeoutNote" />
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
import Confirm from '@/components/utils/Confirm';
export default {
    name: "Notes",
    props: ['notes', 'checkNotes'],
    components: {
        Confirm,
    },
    data() {
        return {
            
        }
    },
    methods: {
        strikeoutNote: function(cdiNoteID) {
            if (cdiNoteID) {
                store.apiWithObjectLoad('strikeout_note', { 'params': cdiNoteID });
            }
        },
        checkNote: function(cdiNoteID) {
            store.apiWithObjectLoad('check_note', { 'params': cdiNoteID });
        },
        saveCheckNotes: function() {
            store.save('check_notes', !this.checkNotes * 1);
        }
    },
}
</script>

<style scoped>

</style>