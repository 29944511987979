<template>
    <div>
        <h3>Ship To</h3>
        <p>Surcharges may be assessed based on the shipping zip code or state.</p>
        <p><i>* denotes required field for quote</i></p>
        <div v-show="!formFields.zip.val">
            <div class="form-inline spacing_btm">
                <div class="form-group">
                    <label>Enter Zip * </label>
                    <input type="text" v-model="zipSearch" ref="initialZip" class="form-control">
                </div>
                <input type="button" @click="addressSearch" value="Next" class="btn btn-default"> or
                <label><input type="checkbox" v-model="isWillCall" @change="willCall"> Will Call</label>
            </div>
            <div v-show="addressSuggestions.length && !addressError">
                <h4>Select Your City</h4>
                <div class="row">
                    <div v-for="(suggestion, id) in addressSuggestions" :key="id"
                         class="col-lg-3 col-sm-6 col-xs-12 form-group">
                        <button type="button" @click="setShipTo(suggestion)"
                                class="cdi_clickable btn btn-default form-control">
                            {{ suggestion.city }} {{ suggestion.state }}, {{ suggestion.zip }}
                        </button>
                    </div>
                </div>
            </div>
            <div v-show="addressError">
                <p class="text-danger">{{ addressError }}</p>
            </div>
            <div v-show="addressContext.length">
                <h4>Or Select a Previous Address</h4>
                <div class="row">
                    <div v-for="(context, id) in addressContext" :key="id"
                         class="col-lg-4 col-sm-6 col-xs-12 form-group">
                        <button @click="setShipTo(context)" type="button"
                                class="cdi_clickable btn btn-default form-control form_control_tall">
                            {{ context.address_name }}<br>
                            {{ context.address }}<br>
                            {{ context.city }} {{ context.state }}, {{ context.zip }}
                        </button>
                        <button @click="$refs.deleteConfirm.open(context.cdi_address_id)" type="button"
                                class="cdi_clickable btn btn-default form-control form_control_tall remove-btn">
                            Remove Suggestion
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="formFields.zip.val">
            <div class="row">
                <div class="col-md-6 col-xs-12 form-group">
                    <label>Name</label>
                    <input type="text" v-model="formFields.shipToName.val" class="form-control">
                </div>
            </div>
            <div class="row">
                <div class="hide_in_mini_shipto col-md-6 col-xs-12 form-group">
                    <label>Address</label>
                    <textarea v-model="formFields.address.val" class="form-control"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-xs-12 form-group">
                    <label>City *</label>
                    <Typeahead ref="city"
                               :acceptPlainText="true"
                               @valueChange="captureTypeAheadText"
                               @objectSelected="captureTypeAheadText"
                               cdi_type="city"
                    />
                </div>
                <div class="col-md-4 col-xs-12 form-group">
                    <label>State *</label>
                    <select class="form-control" v-model="formFields.state.val">
                        <option v-for="[stateAbbr, state] in cache.stateCache.STATES" v-bind:key="stateAbbr"
                                :value="stateAbbr">{{ state }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4 col-xs-12 form-group">
                    <label>Zip *</label>
                    <input type="text" v-model="formFields.zip.val" class="form-control" ref="zip" @keyup="onZipKeyup">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-xs-12 form-group">
                    <label>Shipping Instructions</label>
                    <textarea class="form-control" v-model="formFields.instructions.val"></textarea>
                </div>
            </div>
        </div>
        <Confirm ref="deleteConfirm" heading="Remove Previous Address?"
                 :body="`This address will no longer be suggested to you.`" @response="deleteSuggestedAddress"/>
        <input type="button" class="btn btn-default spacing_right_small" :disabled="!canSubmit" value="Save"
               @click="save">
        <input type="button" class="btn btn-default" value="Cancel" @click="cancel">

    </div>
</template>

<script>
import {store} from '@/store/BusinessObject.store.js';
import Typeahead from '@/components/utils/typeaheads/Typeahead';
import stateCache from '@/cache/state.cache.js';
import helpers from "@/store/helpers";
import Confirm from '@/components/utils/Confirm';


export default {
    name: "ShipToAddress",
    props: ['distributorID'],
    components: {
        Typeahead,
        Confirm
    },
    data() {
        return {
            state: store.state,
            formFields: {
                shipToName: {val: '', qsField: '[shipto][address_name]'},
                address: {val: '', qsField: '[shipto][address]'},
                city: {val: '', qsField: '[shipto][city]'},
                state: {val: '', qsField: '[shipto][state]'},
                zip: {val: '', qsField: '[shipto][zip]'},
                instructions: {val: '', qsField: '[shipto_instructions]'},
            },
            zipSearch: '',
            isWillCall: false,
            addressSuggestions: [],
            addressContext: [],
            addressError: '',
            cache: {
                stateCache,
            },
        }
    },
    methods: {
        onZipKeyup(event) {
            if (event.key === 'Backspace') {
                this.addressSuggestions = [];
                this.formFields.zip.val = '';
                this.$nextTick(() => {
                    this.zipSearch = this.zipSearch.slice(0, -1);
                    this.$refs.initialZip.focus();
                });
            }
        },
        deleteSuggestedAddress: function (cdi_address_id) {
            if (confirm) {
                store.apiGeneric('address/remove_record_of_address', {
                    'params[cdi_address_id]': cdi_address_id,
                    'params[distributor_id]': this.distributorID
                }).then(this.addressSearch)
            }
        },
        load: function () {
            this.formFields.shipToName.val = this.state.object.data.shipto_name;
            this.formFields.address.val = this.state.object.data.shipto_address;
            this.formFields.city.val = this.state.object.data.shipto_city;
            this.formFields.state.val = this.state.object.data.shipto_state;
            this.formFields.zip.val = this.state.object.data.shipto_zip;
            this.formFields.instructions.val = this.state.object.data.shipto_instructions;
            this.$refs.city.object = this.state.object.data.shipto_city;

            if (this.formFields.state.val && this.formFields.zip.val) {
                this.setShipTo({});
            }
        },
        addressSearch: function () {
            if (this.zipSearch.length < 5) {
                return;
            }

            store.apiGeneric('address/from_zip', {'params[zip]': this.zipSearch, 'params[user_id]': this.distributorID})
                .then(results => {
                    this.addressSuggestions = results.suggestions;
                    this.addressContext = results.context;

                    if (results.suggestions.length === 0) {
                        this.addressError = `We can't find any cities for ${this.zipSearch}. Check your zip and try again.`;
                        this.formFields.zip.val = this.zipSearch;
                    } else {
                        this.addressError = '';
                    }
                });
        },
        captureTypeAheadText: function (obj) {
            this.formFields.city.val = obj.value;
        },
        setShipTo: function (address) {
            if (Object.keys(address).length) {
                this.formFields.shipToName.val = address.address_name;
                this.formFields.address.val = address.address;
                this.formFields.city.val = address.city;
                this.$refs.city.object = address.city; // display for Typeahead
                this.formFields.state.val = address.state;
                this.formFields.zip.val = address.zip;
            }
        },
        willCall: function () {
            if (this.isWillCall) {
                this.zipSearch = 'Will Call';
                this.addressSearch();
            } else {
                this.zipSearch = '';
                this.addressSuggestions = [];
                this.addressContext = [];
            }
        },
        save: function () {
            store.apiWithObjectLoad('save_shipto_address', helpers.getQSPairs(this.formFields))
                .then(() => this.state.currentView = 'details');
        },
        cancel: function () {
            this.state.currentView = 'details';
        }
    },
    computed: {
        view: function () {
            return this.state.currentView;
        },
        canSubmit: function () {
            return (
                !this.$isEmpty(this.formFields.city.val) &&
                !this.$isEmpty(this.formFields.state.val) &&
                !this.$isEmpty(this.formFields.zip.val)
            );
        }
    },
    watch: {
        view: function () {
            if (this.view == 'shipToAddress') {
                this.load();
            }
        },
    },

}
</script>

<style scoped>
.remove-btn {
    margin-top: -10px;
    font-size: x-small;
    text-align: center;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
</style>