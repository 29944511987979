<template>
    <Modal :heading="'Print Quote'" @close="close()" v-if="show">
        <template v-slot:body>
            <b>Options</b>
            <ul class="simple_list spacing_left">
                <li><label><input type="checkbox" v-model="noDrawings" true-value="1" false-value=""> No Drawings</label></li>
                <li><label><input type="checkbox" v-model="hidePrices" true-value="1" false-value=""> Hide Prices</label></li>
                <li><label><input type="checkbox" v-model="lineItemPricing" true-value="1" false-value=""> Line Item Pricing</label></li>
            </ul>
        </template>
        <template v-slot:footer>
            <ProtectedLink :id="quoteID" :linkType="'button'" :text="'Create PDF'" @click="close" :classes="'cdi_button btn btn-default'" :queryString="queryString" :type="'quote'" />
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/utils/Modal';
import helpers from "@/store/helpers";
import ProtectedLink from "../../utils/ProtectedLink";
export default {
    name: "Print",
    components: {
        ProtectedLink,
        Modal
    },
    data() {
        return {
            noDrawings: '',
            hidePrices: '',
            lineItemPricing: '',
            show: false,
        }
    },
    props: ['quoteID'],
    methods: {
        open: function() {
            this.show = true;
        },
        close: function() {
            this.show = false;
        }
    },
    computed: {
        queryString: function() {
            const params = helpers.getQSString({ 'params[no_drawings]': this.noDrawings, 'params[hide_prices]': this.hidePrices, 'params[line_item_pricing]': this.lineItemPricing });
            return `genid=${Math.floor(Math.random()*101)}&${params}`;
        }
    }
}
</script>

<style scoped>

</style>