<template>
    <div>
        <h1>New Quote</h1>
        <div v-show="step == 0">
            <h3>Select Customers <small>(CDI Only)</small></h3>
            <div class="row">
                <div class="col-sm-6 col-xs-12 form-group"><label>Customer</label>
                    <Typeahead cdi_type="search_my_users" :params="{'active_only': true}" v-bind:ID.sync="distributorLink" />
                </div>
            </div>
            <div class="cdi_button">
                <input value="Continue" type="submit" class="btn btn-default" @click="setStep">
                <input value="Cancel" @click="cancel" type="button" class="btn btn-default">
            </div>
        </div>

        <div v-show="step == 1">
            <h3>Enter Job Tag</h3>
                <div class="row">
                <div class="col-sm-6 col-xs-12 form-group">
                    <label>Job Tag</label>
                    <input type="text" class="form-control" v-model="jobTag">
                </div>
            </div>
            <input type="submit" value="Continue" class="cdi_button btn btn-default" @click="setStep">
        </div>

        <AddItem v-show="step == 2" :quoteID="0" :jobTag="jobTag" :distributorID="distributorID" :editCurb="{}" />

    </div>
</template>

<script>
import Typeahead from '@/components/utils/typeaheads/Typeahead';
import AddItem from './AddItem.vue';
export default {
    name: "New",
    components: {Typeahead, AddItem},
    data() {
        return {
            step: 0,
            distributorLink: '',
            jobTag: '',
        }
    },
    methods: {
        setStep: function() {
            let step = 0;
            if (this.distributorID && this.jobTag) {
                step = 2;
            }else if (this.distributorID) {
                step = 1;
            }
            
            this.step = step;
        },
        cancel: function() {
            this.$router.push({ path: '/quote' });
            this.$emit('cancel');
        }
    },
    computed: {
        distributorID: function() {
            let distributorID = 0;
            if (!this.$appStore.isStaff()) {
                distributorID = this.$appStore.state.user.userID                
            }else{
                distributorID = this.distributorLink.replace("/user/","")
            }

            return distributorID;
        }
    },
    created() {
        if (!this.$appStore.isStaff()) {
            this.step = 1;
        }
    }
}
</script>

<style scoped>

</style>