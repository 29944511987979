<template>
    <div>
        <form id="auto_drawing_form_pitched">
            <div v-show="isPitchUnknown">
                <div class="row">
                    <div class="col-md-6 form-group">
                        <div class="input-group">
                            <label class="od_label input-group-addon">Pitch (inches per ft)</label>
                            <input type="text" v-model="state.autoDrawing.pitchUnknown"  @keyup="checkForErrors" class="form-control pitch_unknown">
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="is9999" class="spacing_btm">
                <div class="row">
                    <div class="col-md-3 form-group">
                        <div class="input-group">
                            <label class="od_label input-group-addon">OD1</label>
                            <input type="text" v-model.number="state.autoDrawing.od1" @keyup="updateLabel('od1', state.autoDrawing.od1)" class="form-control auto_drawing_od1">
                        </div>
                    </div>
                    <div class="col-md-3 form-group">
                        <div class="input-group">
                            <label class="od_label input-group-addon">OD2</label>
                            <input type="text" v-model.number="state.autoDrawing.od2" @keyup="updateLabel('od2', state.autoDrawing.od2)" class="form-control auto_drawing_od2">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 form-group">
                        <div class="input-group">
                            <label class="return_label input-group-addon">R1</label>
                            <input type="text" v-model.number="state.autoDrawing.r1" @keyup="updateLabel('r1', state.autoDrawing.r1)" class="form-control auto_drawing_r1">
                        </div>
                    </div>
                    <div class="col-md-3 form-group">
                        <div class="input-group">
                            <label class="return_label input-group-addon">R2</label>
                            <input type="text" v-model.number="state.autoDrawing.r2" @keyup="updateLabel('r2', state.autoDrawing.r2)" class="form-control auto_drawing_r2">
                        </div>
                    </div>
                    <div class="col-md-3 form-group">
                        <div class="input-group">
                            <label class="return_label input-group-addon">R3</label>
                            <input type="text" v-model.number="state.autoDrawing.r3" @keyup="updateLabel('r3', state.autoDrawing.r3)" class="form-control auto_drawing_r3">
                        </div>
                    </div>
                    <div class="col-md-3 form-group">
                        <div class="input-group">
                            <label class="return_label input-group-addon">R4</label>
                            <input type="text" v-model.number="state.autoDrawing.r4" @keyup="updateLabel('r4', state.autoDrawing.r4)" class="form-control auto_drawing_r4">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 form-group">
                        <div class="input-group">
                            <label class="supply_label input-group-addon">S1</label>
                            <input type="text" v-model.number="state.autoDrawing.s1" @keyup="updateLabel('s1', state.autoDrawing.s1)" class="form-control auto_drawing_s1">
                        </div>
                    </div>
                    <div class="col-md-3 form-group">
                        <div class="input-group">
                            <label class="supply_label input-group-addon">S2</label>
                            <input type="text" v-model.number="state.autoDrawing.s2" @keyup="updateLabel('s2', state.autoDrawing.s2)" class="form-control auto_drawing_s2">
                        </div>
                    </div>
                    <div class="col-md-3 form-group">
                        <div class="input-group">
                            <label class="supply_label input-group-addon">S3</label>
                            <input type="text" v-model.number="state.autoDrawing.s3" @keyup="updateLabel('s3', state.autoDrawing.s3)" class="form-control auto_drawing_s3">
                        </div>
                    </div>
                    <div class="col-md-3 form-group">
                        <div class="input-group">
                            <label class="supply_label input-group-addon">S4</label>
                            <input type="text" v-model.number="state.autoDrawing.s4" @keyup="updateLabel('s4', state.autoDrawing.s4)" class="form-control auto_drawing_s4">
                        </div>
                    </div>
                </div>

                <canvas id="autoDrawingPitched">Custom Drawing Diagram</canvas>
            </div>

            <Walls :is9999="is9999" />
        </form>
    </div>
</template>

<script>
    import { autoDrawingStore } from "@/components/businessObjects/quote/AutoDrawings/AutoDrawing.store";
    import Walls from "@/components/businessObjects/quote/AutoDrawings/Walls";

    export default {
        name: "AutoDrawingPitched",
        components: {Walls},
        props: ["isEngineering", "is9999", "isPitchUnknown", "isCustom"],
        data() {
            return {
                state: autoDrawingStore.state
            }
        },
        methods: {
            updateLabel: function (key, value) {
                autoDrawingStore.updateLabel(key, value);
                autoDrawingStore.checkForErrors();
            },
            checkForErrors: function () {
                autoDrawingStore.checkForErrors();
            }
        }
    }
</script>

<style scoped>

</style>