<template>
    <div>
      <div v-if="loading">
        <p>We are currently loading your order you will be redirected when it is completed.</p>
      </div>
      <div v-show="!loading">
        <div v-if="id">
            <h1>Quote {{ state.object.data.quote_id }}</h1>
            <div v-show="!state.object.data.shipto_zip || !state.object.data.shipto_state" class="alert alert-warning">
                You have not yet entered shipping information. Prices are subject to change with an additional shipping surcharge on some destinations. Accurate price will populate after shipping destination is entered.
            </div>
            <div v-show="isCurrentView('details')">
                <p style="display: inline-block; padding: 3px"><strong>&nbsp;Quote Valid For 30 Days</strong></p>
                <p style="display: inline-block;"><strong>| Valid From: {{ new Date(state.object.data.quote_date).toLocaleDateString() }}</strong></p>
                <div v-if="newCopiedQuoteID" class="alert alert-success alert-dismissible">
                    <span class="close" @click="newCopiedQuoteID = ''">&times;</span>
                    <strong>Quote successfully copied!</strong> Continue to copied <a :href="`/quote/${newCopiedQuoteID}`" target="_blank" class="alert-link">quote #{{ newCopiedQuoteID }}</a>
                </div>
                <div v-if="state.object.data.quote_status_id == 6 && isStaff" class="alert alert-danger">
                    <p><strong>This quote was deleted by the customer</strong></p>
                </div>

                <div v-if="state.object.data.distributor_notes && isStaff" class="alert alert-info">
                    This Customer/Company has Notes! <span class="cdi_link" @click="showDistributorNotes = !showDistributorNotes">(Click to toggle view)</span>
                    <div v-show="showDistributorNotes">
                        <p style="white-space: pre-line">{{ state.object.data.distributor_notes }}</p>
                    </div>

                </div>

                <div v-if="state.object.data.drawings_verified" class="alert alert-success">Drawings verified. Quote is ready to be ordered.</div>
                <div v-if="state.object.data.distributor_po && state.object.data.cdi_to_measure && !isStaff" class="alert alert-danger">
                    <p><strong>This Quote has not been Verified</strong></p>
                    <p>You have requested that CDI send a representative to measure this job.  We will be in contact with the appropriate parties that you have provided to schedule a site visit.  Once the site visit is completed, we will place your order into production.</p>
                </div>
                <div v-if="state.object.data.distributor_po && !state.object.data.cdi_to_measure && !isStaff" class="alert alert-danger">
                    <p><strong>This Quote has not been Verified</strong></p>
                    <p>If this order is verified, select 'Place Order' and fill in the form (<a target='_blank' href='https://www.youtube.com/watch?v=fq-TAtj-Jsw'>help</a>).</p>
                    <p><strong> - OR - </strong></p>
                    <p>Send us your PO (with CDI quote # {{ state.object.data.quote_id }} written on it) and verification drawing (with PO # or CDI quote # {{ state.object.data.quote_id }} written on it) to your sales representative via email <a :href="`mailto:${state.object.data.sales_email}`">{{ state.object.data.sales_email }}</a>.</p>
                </div>

              <div class="cdi_button spacing_btm">
                <span v-if="locked" class="glyphicon glyphicon-lock"></span>
                <div class="btn-group btn_grp_responsive">
                  <input type="button" value="Add Item" @click="state.currentView = 'addItem'" title="Add another Product to your quote" class="btn btn-default" :disabled="locked">
                  <input type="button" value="Add Note" @click="state.currentView = 'addNote'" title="Add a Note to this quote" class="btn btn-default" :disabled="locked">

                  <input type="button" value="Print" @click="openModal('print')" title="Create a print friendly PDF of this quote" class="btn btn-default" >
                  <input type="button" value="Email" @click="openModal('email')" class="btn btn-default" title="Send a print friendly PDF of this quote and drawings to yourself and others" >
                  <input v-if="isStaff" type="button" value="Copy" @click="copy()" class="lock btn btn-default" title="Copy this quote and carry over its details">
                  <input v-if="!isStaff" type="button" class="btn btn-default" value="Delete Quote" @click="$refs.deleteConfirm.open(state.object.data.quote_id)" title="Delete this quote" :disabled="locked">
                  <input type="button" v-show="user.authorization.can_order" :value="(state.object.data.shipto_zip && state.object.data.shipto_state) ? 'Check Out (Place Order)' : 'Proceed to Pricing'" @click="setCheckout" class="btn btn-primary" :disabled="locked && !isStaff">
                </div>

              </div>
              <div>
                <button class="btn btn-default" @click="openModal('dimensions')" title="Look up your curb by dimensions"><span class="badge highlight-badge">NEW</span> Lookup By Dimension</button>
                <input type="button" value="Pre-Measure Sheet" @click="state.currentView = 'preMeasure'" class="btn btn-default" title="'NEW' Fill out a form regarding what you need measured" >
              </div>
              <div>
                <div class="checkbox">
                  <label class="checkbox-label" v-if="isStaff">
                    <input type="checkbox" @change="toggleHold()" :checked="state.object.data.hold"> Hold
                  </label>
                  <label class="checkbox-label" v-if="isStaff">
                    <input type="checkbox" @change="toggleDead()" v-model="state.object.data.quote_status_id" true-value="4" false-value="1"  :disabled="locked"> Dead
                  </label>
                </div>
              </div>

                <Details :data="state.object.data" />
                <UserCcs />
                <Items :quoteCurbs="state.object.data.quote_curbs" :locked="locked" @upload="load" @changeView="changeView" />
                <Pricing
                    v-show="showPricing"
                    :pricing="state.object.data.pricing"
                    :quoteCurbs="state.object.data.quote_curbs"
                    :freight="{ ltl: state.object.data.est_freight_ltl, truckload: state.object.data.est_freight_truckload }"
                    :surcharges="state.object.data.surcharges"
                    :surchargeTotal="state.object.data.surcharge_total"
                />

                <Notes v-if="showNotes" :notes="state.object.data.quote_notes" :checkNotes="state.object.data.check_notes" />
                <Files v-show="showFiles" :files="state.object.data.customer_files" :tools="false" />
                <Files v-if="isStaff" v-show="showFiles" :files="state.object.data.internal_files" :title="'Internal Files'" />
                <div class='col-sm-12'>
                  <input type="button" v-show="user.authorization.can_order" :value="(state.object.data.shipto_zip && state.object.data.shipto_state) ? 'Check Out (Place Order)' : 'Proceed to Pricing'" @click="setCheckout" class="lock btn btn-primary" :disabled="locked && !isStaff">
                </div>
                <History v-if="isStaff" :history="state.object.data.history" />
            </div>

            <AddItem v-show="isCurrentView('addItem') || isCurrentView('editItem')" :quoteID="state.object.id" :editCurb="activeQuoteCurb" @changeView="changeView" />
            <AddNote v-show="isCurrentView('addNote')" />
            <Checkout v-show="isCurrentView('checkout')" :distributorID="state.object.data.distributor_id" :surchargeTotal="state.object.data.surcharge_total"  @changeView="changeView" @loadComplete="loadComplete" />
            <Print ref="printModal" :quoteID="state.object.id" />
            <Email ref="emailModal" :quoteID="state.object.id" />
            <DimensionLookUp ref="dimensions" />
            <PreMeasure v-show="isCurrentView('preMeasure')" :data="preMeasure" />
            <Confirm ref="deleteConfirm" :heading="'Delete Quote'" :body="'Are you sure you want to delete your quote?'" @response="deleteQuote" />
            <Estimator v-show="isCurrentView('estimator')" :businessObject="'quote_curb'" :curb="activeQuoteCurb" @changeView="changeView" />
            <AutoDraw v-show="isCurrentView('autoDraw')" :curb="activeQuoteCurb" @changeView="changeView" @showConfirmation="showAutoDrawConfirmation = true" />
            <Modal heading="Notice" v-if="showAutoDrawConfirmation" @close="showAutoDrawConfirmation = false">
                <template v-slot:body>
                    Your automated drawing request has been submitted
                </template>
                <template v-slot:footer>
                    <button class="btn btn-default" @click="showAutoDrawConfirmation = false">Ok</button>
                </template>
            </Modal>
            <Modal heading="Pricing Not Ready" ref="priceNotReadyModal" v-if="showPricingNotReadyModal" @close="showPricingNotReadyModal = false" >
                <template v-slot:body>
                    <p>Pricing is not yet prepared for this order. Please enter the following missing information to complete your quote.</p>
                    <ul>
                        <li v-show="!state.object.data.shipto_state">Shipping State</li>
                        <li v-show="!state.object.data.shipto_zip">Shipping Zip Code</li>
                    </ul>
                </template>
                <template v-slot:footer>
                    <button class="btn btn-default" @click="showPricingNotReadyModal = false">Ok</button>
                </template>
            </Modal>
            <ShipToAddress v-show="isCurrentView('shipToAddress')" :distributorID="state.object.data.distributor_id" />
        </div>

        <New v-else-if="isCurrentView('new')" @cancel="state.currentView = 'search'" @changeView="changeView" />

        <Search v-else />
      <div v-show="loading">
        <LoadingIcon :active="loading" />
      </div>
      </div>
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
import { quoteStore } from './Quote.store.js';
import Search from '@/components/businessObjects/utils/Search';
import Details from './Details.vue';
import History from '@/components/businessObjects/utils/History';
import Items from './Items.vue';
import AddItem from './AddItem.vue';
import AddNote from './AddNote.vue';
import Checkout from './Checkout.vue';
import Print from './Print.vue';
import Email from './Email.vue';
import DimensionLookUp from "./DimensionLookUp";
import PreMeasure from './PreMeasure.vue';
import Confirm from '@/components/utils/Confirm';
import Estimator from '@/components/businessObjects/utils/Estimator';
import AutoDraw from './AutoDrawings/AutoDraw.vue';
import Pricing from './Pricing.vue';
import Notes from './Notes.vue';
import New from './New.vue';
import Files from '@/components/businessObjects/utils/Files';
import UserCcs from '@/components/businessObjects/utils/UserCcs';
import Modal from '@/components/utils/Modal';
import ShipToAddress from "./ShipToAddress";
import appStore from "@/store/App.store";
import LoadingIcon from "@/components/utils/LoadingIcon.vue";
export default {
    name: "Container",
    data() {
        return {
            state: store.state,
            quoteState: quoteStore.state,
            newCopiedQuoteID: 0,
            showDistributorNotes: false,
            activeQuoteCurb: {},
            preMeasure: {},
            showAutoDrawConfirmation: false,
            showPricingNotReadyModal: false,
            payMethod: this.$appStore.state.user.authorization.credit_card_payment_only,
            orderFound: false,
            loading: false,
        }
    },
    props: ['id','view'],
    components: {
        Search,
        Details,
        History,
        Items,
        AddItem,
        AddNote,
        Checkout,
        Print,
        Email,
        DimensionLookUp,
        PreMeasure,
        Confirm,
        Estimator,
        AutoDraw,
        Pricing,
        Notes,
        Files,
        New,
        UserCcs,
        ShipToAddress,
        Modal,
        LoadingIcon
    },
    created() {
        if (!this.$appStore.state.user.authorization.view_quote_pricing) {
            delete this.quoteState.search.resultFields.total
        }

        let view = undefined;
        if (this.id === 'new') {
            view = 'new';
            this.id = undefined
        }

        store.initialize(this.quoteState, view);
        this.load();
    },
    methods: {
        load: function() {
            if (this.id){
                store.load(this.id)
                    .then(() => this.$appStore.setTitle([this.state.object.data.quote_id, this.state.titlePlural]));
            }
        },
        isCurrentView: function(view) {
            return view === this.state.currentView;
        },
        copy: function() {
            store.api('copy')
                .then(results => {
                    if (!results.new_copied_quote_id) {
                        this.$appStore.errorBox(results.msg);
                    }else{
                        this.newCopiedQuoteID = results.new_copied_quote_id;
                    }
                });
        },
        toggleHold: function() {
            store.apiWithObjectLoad('toggle_hold_quote');
        },
        toggleDead: function() {
            store.apiWithObjectLoad('update_status', { 'params[updated_status_id]': this.state.object.data.quote_status_id });
        },
        deleteQuote: function(quoteID) {
            if (quoteID) {
                store.api('update_status', { 'params[updated_status_id]': 6 });
                this.state.currentView = 'search';
                this.$router.push({ path: '/quote' });
            }
        },
        changeView: function(data) {
            if (data.reload) {
                this.load();
            }
            this.activeQuoteCurb = data;
            this.state.currentView = data.view;
            //window.scrollTo(0,0);
        },
        setCheckout: function() {
            if (!this.state.object.data.shipto_state || !this.state.object.data.shipto_zip) {
                this.state.currentView = 'shipToAddress'
            } else {
                store.apiWithObjectLoad('check_for_curb_errors')
                    .then(() => this.state.object.data.opt != 'error' ? this.state.currentView = 'checkout' : '');
            }
        },
        openModal: function(type) {
            if (type === 'print') {
                this.$refs.printModal.open();
            } else if(type === 'email') {
                this.$refs.emailModal.open();
            }else if(type === 'dimensions'){
                this.$refs.dimensions.open();
            }
        },
      loadComplete: function (){
        this.loading = false;
      }
    },
    computed: {
        appStore() {
            return appStore
        },
        currentView: function() {
            return this.state.currentView;
        },
        showNotes: function() {
            if (this.state.object.data.quote_notes && this.state.object.data.quote_notes.length){
                return true;
            }else{
                return false;
            }
        },
        showFiles: function() {
            let show = false;
            if (this.state.object.data.customer_files || this.state.object.data.internal_files) {
                if (this.state.object.data.customer_files.length || this.state.object.data.internal_files.length) {
                    show = true;
                }
            }
            return show;
        },
        locked: function() {
            return this.state.object.data.locked;
        },
        isStaff: function() {
            return this.$appStore.isStaff();
        },
        fileType: function() {
            return this.$cdiVars.FILE_TYPE_THREED_DRWNG;
        },
        user: function() {
            return this.$appStore.state.user;
        },
      showPricing: function() {
        if (this.state.object.data.is_canadian_order) {
          if (!this.state.object.data.shipto_zip) {
            return false;
          }
        }
        return this.appStore.state.user.authorization.view_quote_pricing;
      }
    },
    watch: {
        id: function (){
            this.load();
        },
        currentView: function() {
            if (this.currentView == 'preMeasure'){
                store.apiGeneric(`quote_pre_measure_sheet/to_json/${this.id}`, {})
                    .then(results => this.preMeasure = results);
            }else if(this.currentView == 'search'){
                window.location.reload()
            } else if (this.state.currentView.includes('override_')){
                store.initialize(this.quoteState);
            }
        }
    },
  mounted() {
    const param = (this.$route.query.param) ? this.$route.query.param : undefined;
    if (param !== undefined) {
      this.loading = true;
      setTimeout(() => {
        this.setCheckout();
      }, 5000);
    }
  }
}
</script>

<style scoped>
.cdi_button {
    display: flex;

    align-items: center; /* Center items vertically */
}

.btn_grp_responsive {
    max-width: 100%; /* Set maximum width to 50% of the container */

}

.checkboxes {
    display: flex;
    align-items: center;
}
.checkbox-label {
    margin-right: 15px; /* Add margin between checkboxes */
}
.highlight-badge {
    background-color: red;
    color: white;
}
</style>