<template>
    <Modal :heading="'Ship-To State and Zip Code'" @close="close()" v-if="show">
        <template v-slot:body>
            <div class="margin_bottom">
                <label>State</label>
                <select v-model="shipToState" class="form-control">
                    <option v-for="[stateAbbr, state] in cache.stateCache.STATES" v-bind:key="stateAbbr" :value="stateAbbr">{{ state }}</option>
                </select>
            </div>

            <div>
                <label>Zip</label>
                <input class="form-control" type="text"  v-model="shipToZip" />
            </div>

        </template>
        <template v-slot:footer>
            <button class="cdi_button btn btn-primary" type="button" @click="save()">Save</button>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/utils/Modal';
import { store } from '@/store/BusinessObject.store.js';
import stateCache from '@/cache/state.cache.js';
export default {
    name: "ShipToState",
    components: {
        Modal
    },
    data() {
        return {
            shipToState: '',
            shipToZip: '',
            show: false,
            cache: {
                stateCache,
            },
        }
    },
    methods: {
        save: function() {
            if (this.shipToState) {
                store.apiWithObjectLoad('save_shipto_address', {'params[shipto][state]': this.shipToState, 'params[shipto][zip]': this.shipToZip})
                    .then(() => this.clearForm());
            }
            this.close();
        },
        open: function() {
            this.show = true;
        },
        close: function() {
            this.show = false;
        },
        clearForm: function() {
            this.shipToState = '';
        }
    }
}
</script>

<style scoped>

</style>