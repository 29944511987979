<template>
    <div id="auto_drawing_wnd">
        <div v-show="curbTypeID === cache.curbTypeCache.ADAPTER">
            <AutoDrawingAdapter :isEngineering="isEngineeringTeam"/>

            <div class="pull-right form-inline">
                <button :disabled="!state.submittable" @click="submit" class="btn btn-primary">Submit</button>&nbsp;
                <button class="btn btn-default" @click="changeView">Cancel</button>
            </div>
        </div>
        <div v-show="[cache.curbTypeCache.PITCHED, cache.curbTypeCache.METAL_BUILDING_CURB].includes(curbTypeID)">
            <AutoDrawingPitched :isCustom="isCustom"
                                :is9999="isNew9999"
                                :isPitchUnknown="isPitchUnknown"
                                :isEngineering="isEngineeringTeam"/>

            <div class="pull-right form-inline">
                <button :disabled="!state.submittable" @click="submit" class="btn btn-primary">Submit</button>&nbsp;
                <button class="btn btn-default" @click="changeView">Cancel</button>
            </div>
        </div>
        <div v-show="curbTypeID === cache.curbTypeCache.STRAIGHT_CURB">
            <AutoDrawingStraight :isCustom="isCustom" :is9999="isNew9999" :isEngineering="isEngineeringTeam"/>

            <div class="pull-right form-inline">
                <button :disabled="!state.submittable" @click="submit" class="btn btn-primary">Submit</button>&nbsp;
                <button class="btn btn-default" @click="changeView">Cancel</button>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <div class="well fast_draw_notice margin_bottom">
                    <div>
                        <em class="text_big">Powered by</em>
                    </div>
                    <div>
                        <img :src="`${this.baseUrl}/images/fastdraw.png`" class="img-thumbnail">
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { autoDrawingStore } from "@/components/businessObjects/quote/AutoDrawings/AutoDrawing.store";
    import curbTypeCache from "@/cache/curbType.cache";
    import AutoDrawingAdapter from "@/components/businessObjects/quote/AutoDrawings/AutoDrawingAdapter";
    import AutoDrawingPitched from "@/components/businessObjects/quote/AutoDrawings/AutoDrawingPitched";
    import AutoDrawingStraight from "@/components/businessObjects/quote/AutoDrawings/AutoDrawingStraight";

    export default {
        name: "AutoDraw",
        components: {
            AutoDrawingStraight,
            AutoDrawingPitched,
            AutoDrawingAdapter
        },
        props: ['curb'],
        data() {
            return {
                cache: {
                    curbTypeCache
                },
                state: autoDrawingStore.state,
                isEngineeringTeam: this.$appStore.state.user.autoDrawingUserTeam === 'ENGINEERING',
                type: null,
            }
        },
        methods: {
            changeView: function () {
                autoDrawingStore.resetData();
                this.$emit('changeView', {view: 'details'});
            },
            submit: function () {
                autoDrawingStore.submit().then(() => {
                    this.$emit('changeView', {view: 'details'});
                    this.$emit('showConfirmation');
                });
            }
        },
        computed: {
            baseUrl() {
                return process.env.VUE_APP_URL;
            },
            isCustom() {
                return this.curb.is_custom;
            },
            isNew9999() {
                return this.curb.new_part_num && this.curb.new_part_num.indexOf('9999') !== -1;
            },
            isPitchUnknown () {
                return this.curb.pitch_id === 9;
            },
            curbTypeID() {
                return parseInt(this.curb.curb_type_id);
            }
        },
        watch: {
            curb: function () {
                if (!this.curb.quote_curb_id) {
                    return;
                }

                let prefix = null;
                let canvasID = null;
                let wallOptions = null;

                switch (this.curbTypeID) {
                    case curbTypeCache.ADAPTER:
                        prefix = '#auto_drawing_form_adapter';
                        canvasID = 'autoDrawingAdapter';
                        break;
                    case curbTypeCache.STRAIGHT_CURB:
                        prefix = '#auto_drawing_form_straight';
                        canvasID = 'autoDrawingStraight';
                        wallOptions = true;
                        break;
                    case curbTypeCache.PITCHED:
                    case curbTypeCache.METAL_BUILDING_CURB:
                        prefix = '#auto_drawing_form_pitched';
                        canvasID = 'autoDrawingPitched';
                        wallOptions = true;
                        break;
                }

                autoDrawingStore.load({
                    quoteCurbID: this.curb.quote_curb_id,
                    quoteCurbData: this.curb,
                    curbTypeID: this.curbTypeID,
                    domPrefix: prefix,
                    canvasID: canvasID,
                    wallOptions: wallOptions
                });
            }
        }
    }
</script>

<style scoped>
@import '../../../../assets/css/auto_drawing.css';
</style>