<template>
    <div>
        <h3>Add Note</h3>
        <div>
            <textarea v-model="cdiNote" class="form-control"></textarea>
            <br>
            <div v-if="$appStore.isStaff()">
                <label><input type="checkbox" v-model="internal" :true-value="1" :false-value="''"> Internal <small>(hidden from customers)</small></label>
            </div>
            <input type="button" value="Save Note" class="btn btn-default" @click="save">
            <input type="button" value="Cancel" class="btn btn-default" @click="close">
        </div>
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
export default {
    name: "AddNote",
    data() {
        return {
            state: store.state,
            cdiNote: '',
            internal: '',
        }
    },
    methods: {
        save: function() {
            store.apiWithObjectLoad('add_notes', { 'params[cdi_note]': this.cdiNote, 'params[internal]': this.internal });
            this.close();
        },
        close: function() {
            this.cdiNote = '';
            this.internal = '';
            this.state.currentView = 'details';
        }
    }
}
</script>

<style scoped>

</style>