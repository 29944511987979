import stateCache from "@/cache/state.cache";
import appStore from "@/store/App.store";

function getScopeOptions() {
    let arr = [];
    arr.push([0, 'My Quotes']);
    if (appStore.state.user.authorization.see_branch_orders > 0) {
        arr.push([1, 'My Branch'])
    }
    if (appStore.state.user.authorization.see_company_orders > 0) {
        arr.push([2, 'My Company'])
    }
    return arr;
}


export const quoteStore = {
    state: {
        name: 'quote',
        title: 'Quote',
        titlePlural: 'Quotes',
        nav: {
            base: 'quote',
            newView: true,
            views: [
                { view: 'details', label: 'Details', },
            ],
        },
        search: {
            sortFields: true,
            endPoint: 'quotes',
            formFields: {
                quoteID: {
                    val: '',
                    qsField: '[numbers][quote_id]',
                    label: 'Quote ID',
                    component: 'SearchTextInput'
                },
                jobTag: {
                    val: '',
                    qsField: '[fstrings][job_tag]',
                    label: 'Job Tag',
                    component: 'SearchTextInput'
                },
                distributor: {
                    val: '',
                    qsField: '[numbers][distributor_id]',
                    label: 'Customer',
                    cdiType: 'search_my_users',
                    component: 'SearchObjectInput'
                },
                po: {
                    val: '',
                    qsField: '[numbers][distributor_po]',
                    label: 'Customer PO',
                    component: 'SearchTextInput'
                },
                quoteDateFrom: {
                    val: '',
                    qsField: '[dates][quote_date_from]',
                    label: 'Date From',
                    component: 'SearchDateInput'
                },
                quoteDateTo: {
                    val: '',
                    qsField: '[dates][quote_date_to]',
                    label: 'Date To',
                    component: 'SearchDateInput'
                },
                companyID: {
                    val: '',
                    qsField: '[numbers][d.company_id]',
                    label: 'Company',
                    cdiType: 'company',
                    component: 'SearchTypeaheadInput'
                },
                hasPO: {
                    val: '',
                    qsField: '[isset][distributor_po]',
                    label: 'Has PO',
                    component: 'SearchCheckboxInput'
                },
                companyQuotes: {
                    val: '0',
                    qsField: '[company_quotes]',
                    label: 'Company Quotes',
                    component: 'SearchSelectInput',
                    options: getScopeOptions(),
                    excludeDefault: true
                },
                status: {
                    val: '1',
                    qsField: '[numbers][quote_status_id]',
                    label: 'Status',
                    component: 'SearchSelectInput',
                    options: [[1, 'Pending'], [2, 'Competitor'], [3, 'Won'], [4, 'Dead'], [5, 'Fork'], [6, 'Deleted']],
                    toggleHide: true,
                    defaultVal: '1',
                },
                salesUser: {
                    val: '',
                    qsField: '[numbers][sales_user_id]',
                    label: 'Sales Person',
                    cdiType: 'sales_employee',
                    component: 'SearchTypeaheadInput',
                    toggleHide: true,
                    defaultVal: '',
                },
                partID: {
                    val: '',
                    qsField: '[fstrings][curb_txt]',
                    label: 'CDI Part #',
                    component: 'SearchTextInput',
                    toggleHide: true,
                    defaultVal: '',
                },
                distributorCity: {
                    val: '',
                    qsField: '[fstrings][ca.city]',
                    label: 'Customer City',
                    cdiType: 'city',
                    component: 'SearchTypeaheadInput',
                    toggleHide: true,
                    defaultVal: '',
                },
                distributorState: {
                    val: 0,
                    qsField: '[fstrings][ca.state]',
                    label: 'Customer State',
                    component: 'SearchSelectInput',
                    options: stateCache.STATES,
                    toggleHide: true,
                    defaultVal: 0,
                },
                distributorZip: {
                    val: '',
                    qsField: '[fstrings][ca.zip]',
                    label: 'Customer Zip',
                    component: 'SearchTextInput',
                    toggleHide: true,
                    defaultVal: '',
                },
                pageSize: {val: 24, qsField: '[page_size]'},
                searchIndex: {val: 0, qsField: '[search_index]'},
                direction: {val: 'DESC', qsField: '[direction]'},
                orderBy: {
                    val: 'quote_date',
                    qsField: '[orderby]',
                    options: {
                        'quote_date': 'Date',
                        'distributor': 'Customer',
                        'job_tag': 'Job Tag'
                    }
                },
            },
            resultFields: {
                quote_id: {header: 'ID', link: 'quote'},
                quote_date: {header: 'Quote Date', link: ''},
                distributor: {header: 'Customer', link: ''},
                company_name: {header: 'Company', link: ''},
                job_tag: {header: 'Job Tag', link: ''},
                total: {header: 'Total', link: ''},
            }
        },
        object: {},
    },
}