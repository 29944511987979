<template>
    <Modal :heading="'Email Quote & Drawings'" @close="close()" v-if="show">
        <template v-slot:body>
            <b>Options</b>
            <ul class="simple_list">
                <li><label><input type="checkbox" v-model="formFields.emailMe.val" true-value="1" false-value=""> Email Me a PDF of This Quote</label></li>
                <ul class="simple_list spacing_left">
                    <li><label><input type="checkbox" v-model="formFields.noDrawings.val" true-value="1" false-value=""> No Drawings</label></li>
                    <li><label><input type="checkbox" v-model="formFields.hidePrices.val" true-value="1" false-value=""> Hide Prices</label></li>
                    <li><label><input type="checkbox" v-model="formFields.both.val" true-value="1" false-value=""> Send me 2 PDFs</label> (one with Prices and one without Prices)</li>
                    <li><label><input type="checkbox" v-model="formFields.lineItemPricing.val" true-value="1" false-value=""> Line Item Pricing</label></li>
                </ul>
                <li>
                    <div class="form-group">
                        <label><input type="checkbox" @click="emailToCustomer = !emailToCustomer" :checked="emailToCustomer"> Send Drawings to Email below</label> (i.e. your customer)
                        <div class="spacing_left"><input class="form-control" type=text v-model="formFields.customerEmail.val" :disabled="!emailToCustomer"></div>
                    </div>
                </li>
            </ul>

        </template>
        <template v-slot:footer>
            <button class="cdi_button btn btn-default" type="button" @click="email()">Send Email</button>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/utils/Modal';
import { store } from '@/store/BusinessObject.store.js';
import helpers from "@/store/helpers";
export default {
    name: "Email",
    components: {
        Modal
    },
    data() {
        return {
            formFields: {
                emailMe: { val: 1, qsField: '[emailme]'},
                noDrawings: { val: '', qsField: '[no_drawings]'},
                hidePrices: { val: '', qsField: '[hide_prices]'},
                both: { val: 1, qsField: '[both]'},
                lineItemPricing: { val: '', qsField: '[line_item_pricing]'},
                customerEmail: { val: '', qsField: '[email]'},
            },
            emailToCustomer: false,
            show: false,
        }
    },
    props: ['quoteID'],
    methods: {
        email: function() {
            store.api('email', helpers.getQSPairs(this.formFields), false, 'quote_pdf')
                .then(() => this.clearForm());
            this.close();
        },
        open: function() {
            this.show = true;
        },
        close: function() {
            this.show = false;
        },
        clearForm: function() {
            this.formFields.emailMe.val = 1;
            this.formFields.noDrawings.val = '';
            this.formFields.hidePrices.val = '';
            this.formFields.both.val = 1;
            this.formFields.lineItemPricing.val = ''
            this.formFields.customerEmail.val = '';
            this.emailToCustomer = false;
        }
    }
}
</script>

<style scoped>

</style>