<template>
    <div class="col-sm-12">
        <h3>{{ title }}</h3>
        <div class="panel panel-default panel-body flex space-evenly flex-wrap">
            <div class="file" v-for="file in files" v-bind:key="file.cdi_file_id">
                <div class="cdi_file_box" :class="!file.cdi_file_active ? 'inactive_file' : ''">
                    <a v-if="tools" :href="'/cdi_file/' + file.cdi_file_id" target=_blank>
                        <span class="glyphicon glyphicon-info-sign icon_md cdi_link"></span>
                    </a>
                    <a v-if="tools">
                        <span class="update_file_status glyphicon icon_md cdi_link"
                              :class="file.cdi_file_active ? 'glyphicon-eye-open' : 'glyphicon-eye-close'"
                              @click="updateFileStatus(file.cdi_file_id, file.cdi_file_active ? statusInactive : statusEnabled)">
                        </span>
                    </a>
                    <ProtectedLink :id="`${file.cdi_file_id}`" :linkType="'imgText'" :imgSrc="`${imageServer}/api/download/file/${file.cdi_file_id}?thumb=1&${queryStringSrc}`" :text="file.title" :queryString="queryStringLink" :type="'download'" />
                    <img src="" style="visibility: hidden">
                    <span v-if="!file.cdi_file_active" class="large-tip-text">
                        (Inactive)
                    </span>
                    <button v-if="tools" v-show="showToggleVisibility && emitOnly"
                        class="btn btn-primary"
                        @click="toggleVisibility(file.cdi_file_id)">
                        {{ visibilityButtonText}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from "@/store/BusinessObject.store.js";
    import ProtectedLink from "../../utils/ProtectedLink";
    import helpers from "@/store/helpers";

    export default {
        name: "Files",
        props: {
            files: {},
            title: {
                default: 'Files',
            },
            tools: {
                default: true,
            },
            emitOnly: {
                default: false
            },
            visibilityType: {
                default: null
            }
        },
        components : {
            ProtectedLink
        },
        data() {
            return {
                statusEnabled: 0,
                statusDisabled: 1,
                statusInactive: 2,
                state: store.state,
                imageServer: process.env.VUE_APP_URL
            }
        },
        methods: {
            updateFileStatus: function (fileID, statusID) {
                let params = {
                    'params[cdi_file_disable]': statusID,
                    'params[cdi_file_id]': fileID
                };

                if (this.emitOnly) {
                    store.api('set_file_visibility', params).then(data => this.$emit('update', data));

                    return;
                }

                store.api('set_file_visibility', params, 'object.data');
            },
            toggleVisibility: function(cdi_file_id) {
                if (this.emitOnly) {
                    store.api('toggle_file_visibility', {'params[cdi_file_id]': cdi_file_id}).then(data => this.$emit('update', data));
                }
            }
        },
        computed: {
            queryStringLink: function() {
                const params = helpers.getQSString({ 'params' : this.companyId});
                return `genid=${Math.floor(Math.random()*101)}&${params}`;
            },
            queryStringSrc: function() {
                const params = helpers.getQSString({ 'params' : this.companyId});
                return `genid=${Math.floor(Math.random()*101)}&${params}&token=${this.$appStore.getUserFileAccessToken()}`;
            },
            showToggleVisibility: function() {
                return this.$appStore.isStaff();
            },
            visibilityButtonText: function() {
                if (!this.visibilityType) {
                    return 'Toggle Visibility';
                } else {
                    if (this.visibilityType == 'internal') {
                        return 'Make Public';
                    } else {
                        return 'Make Internal';
                    }
                }
            }
        },
    }
</script>

<style scoped>
    .file {
        flex-grow: 1;
        padding: 5px;
        margin: 5px;
    }

    .cdi_file_box > a {
        margin: 5px;
    }
</style>