<template>
    <div >
        <h3>Items</h3>
        <div v-for="quoteCurb in quoteCurbs" v-bind:key="quoteCurb.quote_curb_id">
            <div class="col-sm-12 margin_bottom">
                <div class="line_heading_container">
                    <div class="line_qty">
                        {{ quoteCurb.line }}{{ quoteCurb.qty }}
                    </div>
                    <div class="line_heading">
                        <div>{{ quoteCurb.custom_disp }} <span v-html="quoteCurb.nomenclature_display"></span> {{ quoteCurb.tag }}
                            <div v-if="quoteCurb.curb_variant_id" class="badge badge-primary"> v{{ quoteCurb.curb_variant_id }}</div>
                            <div v-if="quoteCurb.conforming_type" class="badge badge-primary"> Conforming {{ quoteCurb.conforming_type }}</div>
                            <div v-if="quoteCurb.is_oshpd" class="badge badge-alert"> OSHPD </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div class="col-sm-7">
                <div class="line_body">
                    <div v-if="quoteCurb.cdi_only && !$appStore.isStaff()" class="well well-sm spacing_btm">Only CDI can Edit this item. Contact your CDI sales rep for help.</div>
                    <div v-html="quoteCurb.desc"></div>
                    <div v-if="quoteCurb.rtu_accessories_desc"><i>RTU Accessories:</i> {{ quoteCurb.rtu_accessories_desc }}</div>
                    <div v-if="quoteCurb.quote_pre_measure_sheet_line_id">
                        <h5 v-if="!quoteCurb.measured">Needs to be Measured</h5>
                        <h5 v-else-if="quoteCurb.measured == 1">Measured on {{quoteCurb.date_measured}}</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-5">
                <div class="line_drawings well well-sm">
                    <ul class="list-unstyled line_drawings_list">
                        <li v-for="(drawing, index) in quoteCurb.drawings" v-bind:key="index">
                            <ProtectedLink
                                :id="`${drawing.cdi_file_id}`"
                                :linkType="'iconText'"
                                :classes="'glyphicon glyphicon-file cdi_icon'"
                                :text="drawing.title.replace('Drawing','')"
                                :queryString="queryString"
                                :type="'drawing'"
                                :displayInline="true"
                            />
                            &nbsp;
                            <span v-show="drawing.cdi_file_dir == 'customs' || drawing.cdi_file_dir == 'internal'"
                                  class="glyphicon glyphicon-remove"
                                  @click="$refs.deleteDrawingConfirm.open({ quoteCurbID: quoteCurb.quote_curb_id, cdiFileID: drawing.cdi_file_id })"
                            ></span>
                        </li>
                        <li v-if="$appStore.isStaff()">
                            <a target="_new" :href='`/cdi_file?cdi_reference_id=${quoteCurb.quote_curb_id}&enabled_only=0&cdi_file_datetime_from=""&ignore_default=1`'><span class="glyphicon glyphicon-new-window cdi_icon"></span> Past Quote Drawings</a>
                        </li>
                    </ul>

                    <span v-if="quoteCurb.has_dr.drawing_request_id">
                    <i>Open Drawing Request</i>
                    <span v-if="quoteCurb.has_dr.last_clocked_in_employee"><i> - {{ quoteCurb.has_dr.last_clocked_in_employee }}</i></span>
                </span>
                </div>
            </div>
            <div class="col-sm-12 spacing_btm">
                <div v-if="!locked" class="btn-group btn-block btn_row margin-bottom">
                    <button v-if="$appStore.isStaff() || !quoteCurb.cdi_only" class="btn btn-default" type="button" @click="changeView({ ...quoteCurb, ...{view: 'editItem'} })">Edit</button>
                    <button v-if="$appStore.isStaff() || !quoteCurb.cdi_only" class="btn btn-default" type="button" @click="$refs.deleteConfirm.open(quoteCurb.quote_curb_id)">Delete</button>
                    <button v-if="$appStore.isStaff()" class="btn btn-default" type="button" @click="changeView({ ...quoteCurb, ...{view: 'estimator'} })">Estimator</button>
                    <button v-if="!quoteCurb.has_dr.drawing_request_id && $appStore.isStaff()" class="btn btn-default" type="button" @click="$refs.drawingRequest.open(quoteCurb.quote_curb_id, 0, quoteCurb.curb_id)">Draw Request</button>

                    <button
                        v-if="quoteCurb.show_auto_drawing_button && isNonStandardAutoDrawing(quoteCurb)"
                        @click="changeView({ ...quoteCurb, ...{view: 'autoDraw'} })"
                        class="btn btn-default" type="button">
                        Fast Draw
                    </button>
                    <button v-else-if="quoteCurb.show_auto_drawing_button"
                            @click="submitStandardAutoDrawing(quoteCurb.quote_curb_id)"
                            class="btn btn-default" type="button">
                        Fast Draw
                    </button>
                </div>
            </div>
        </div>

        <Confirm ref="deleteConfirm" :heading="'Delete line item?'" :body="'Would you like to delete this line item?'" @response="deleteItem" />
        <Confirm ref="deleteDrawingConfirm" :heading="'Delete Custom Drawing?'" :body="'Would you like to delete the custom drawing?'" @response="deleteDrawing" />
        <DrawingRequest ref="drawingRequest" :companyID="state.object.data.company_id" @submitted="$emit('upload')" />
    </div>

</template>

<script>
import Confirm from '@/components/utils/Confirm';
import { store } from '@/store/BusinessObject.store.js';
import DrawingRequest from "@/components/businessObjects/utils/DrawingRequest";
import { autoDrawingStore } from "@/components/businessObjects/quote/AutoDrawings/AutoDrawing.store";
import ProtectedLink from "@/components/utils/ProtectedLink";
import helpers from "@/store/helpers";

export default {
    name: "Items",
    components: {
        Confirm,
        DrawingRequest,
        ProtectedLink
    },
    props: [ 'quoteCurbs', 'locked' ],
    data() {
        return {
            state: store.state,
        }
    },
    methods: {
        changeView: function(data) {
            this.$emit('changeView', data);
        },
        deleteItem: function(quoteCurbID) {
            if (quoteCurbID) {
                store.apiWithObjectLoad('delete_curb', { 'params[quote_curb_id]': quoteCurbID });
            }

        },
        deleteDrawing: function(data) {
            if (data) {
                store.apiGeneric(`quote_curb/delete_custom_drawing/${data.quoteCurbID}`, { params: data.cdiFileID })
                    .then(() => this.changeView({view: 'details', reload: true}));
            }
        },
        isNonStandardAutoDrawing: function (quoteCurb) {
            let isNew9999 = quoteCurb.new_part_num && quoteCurb.new_part_num.indexOf('9999') !== -1;
            let isOld9999 = quoteCurb.old_part_num && quoteCurb.old_part_num.indexOf('9999') !== -1;
            let isCustom = quoteCurb.is_custom;

            return isNew9999 || isOld9999 || isCustom;
        },
        submitStandardAutoDrawing: function (id) {
            autoDrawingStore.submitStandard(id);
        }
    },
    computed:{
        queryString: function() {
            const params = helpers.getQSString({ 'params' : this.companyId});
            return `genid=${Math.floor(Math.random()*101)}&${params}`;
        }
    }
}
</script>

<style scoped>
.badge-alert{
  color: white;
  background-color: #FF0000;
}
</style>